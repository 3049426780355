import React from 'react';
import ZingChart from 'zingchart-react';
import './statisticModeZielen.css';

export default class StatisticZielen extends React.Component{
  render(){
    
    const {udz_zieleni, udzial_niezieleni, pomn_przyr, pn, pk, rez, n2k, ochk, uz_eko, zpk, st_dok, brak_fop } = this.props;

    const seriesFormOchr = [];

    if(pn!=="0.00"){
        seriesFormOchr.push({values: [parseFloat(pn)],
        text: 'park narodowy', backgroundColor: '#748e00'})
    } 
    if(pk!=="0.00"){
        seriesFormOchr.push({values: [parseFloat(pk)],
        text: 'park krajobrazowy', backgroundColor: '#a6cd39'})
    }
    if(rez!=="0.00"){
        seriesFormOchr.push({values: [parseFloat(rez)],
        text: 'rezerwat', backgroundColor: '#ffbf00'})
    }
    if(n2k!=="0.00"){
        seriesFormOchr.push({values: [parseFloat(n2k)],
        text:'obszar natura 2000', backgroundColor: '#c00000'})
    }
    if(ochk!=="0.00"){
        seriesFormOchr.push({values: [parseFloat(ochk)],
        text: 'obszar chronionego krajobrazu', backgroundColor: '#ffff99'})
    } 
    if(uz_eko!=="0.00"){
        seriesFormOchr.push({values: [parseFloat(uz_eko)],
        text: 'użytek ekologiczny', backgroundColor: '#0070c0'})
    }
    if(zpk!=="0.00"){
        seriesFormOchr.push({values: [parseFloat(zpk)], 
        text: 'zespół przyrodniczo-krajobrazowy', backgroundColor: '#00b0f0'})
    } 
    if(st_dok!=="0.00"){
        seriesFormOchr.push({values: [parseFloat(st_dok)],
        text: 'stanowisko dokumentacyjne', backgroundColor: '#6f30a0'})
    } 
    seriesFormOchr.push({values: [parseFloat(brak_fop)],
    text: 'brak form ochrony przyrody', backgroundColor: '#bfbfbf'});

    const configFormyOchronyPrzyr={
        type: 'ring',
        title: {
            text: 'Udział form ochrony przyrody w powierzchni miasta',
            wrapText: true,
            fontSize: '18px',
            height: '50px',
            fontFamily: 'Open Sans',
        },
        legend:{
            y: '75%',
            x: '0',
            layout: 'x2',
            width: '100%',
            wrapText: true,
        },
        plot:{
            size: '60%',
            offsetY: '-60px',
            animation: {
              effect: "1",
              delay: "2000",
              sequence: '3'
          },
        },
        series: seriesFormOchr,   
    }
    const configUdzialZiel = {
        type: 'bar',
        title:{
            text: 'Udział pow zieleni w mieście wg Studium uwarunkowań i kierunków zagospodarowania przestrzennego',
            fontSize: '16px',
            wrapText: true,
            height: '60px',
            fontFamily: 'Open Sans',
        },
        plot:{
            stacked: true,
            barWidth: '60%',
            animation: {
              effect: "1",
              delay: "2000",
              sequence: '3'
          },
        },
        legend:{
            y: '88%',
            
            margin: '0 auto',
        },
        "scale-x":{
            labels: ['']
         },
        series:[
            {values: [parseFloat(udzial_niezieleni)], text: 'udział pozostałych form zagospodarowania terenu'},
            {values: [parseFloat(udz_zieleni)], text: 'udział zieleni'},
        ]
    }
    

    return(
      <div className="zielen">
        <p className="title">Statystyki - Zieleń miejska</p>
        {udz_zieleni && 
        <>  
            <ZingChart data={configUdzialZiel} />
            <ZingChart data={configFormyOchronyPrzyr}/>
        </>}
        <p className="titleSection">Liczba pomników przyrody</p>
        <div className="pomnik">
            
            <p>{pomn_przyr}</p>
        </div>
      </div>
    );
  }
}
