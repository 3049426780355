import React from 'react';
import ZingChart from 'zingchart-react';
import './statisticModeOdpady.css';

export default class StatisticOdpady extends React.Component {
  render() {
    const {
      il_odp_mieszk,
      zb_sel,
      zb_niesel,
      udz_papier,
      udz_metal,
      udz_szklo,
      udz_tw_szt,
    } = this.props;

    const configTypZbior = {
      type: 'bar',
      plot: {
        stacked: true,
        barWidth: '60%',
        size: '60%',
        animation: {
          effect: '1',
          delay: '2000',
          sequence: '3',
        },
      },
      title: {
        text:
          'Odestek mieszkańców objętych i nieobjętych systemem selektywnej zbiórki odpadów',
        wrapText: true,
        height: '60px',
        fontSize: '18px',
        fontFamily: 'Open Sans',
        margin: '0 0 10px',
      },
      legend: {
        y: '87%',
        margin: '0 auto',
      },
      'scale-x': {
        labels: [''],
      },
      series: [
        { values: [parseFloat(zb_sel)], text: 'zbiórka selektywna' },
        { values: [parseFloat(zb_niesel)], text: 'zbiórka nieselektywna' },
      ],
    };

    const seriesFrakOdpadStrukt = [];

    if (udz_papier !== '0.00') {
      seriesFrakOdpadStrukt.push({
        values: [parseFloat(udz_papier)],
        text: 'Papier',
      });
    }
    if (udz_metal !== '0.00') {
      seriesFrakOdpadStrukt.push({
        values: [parseFloat(udz_metal)],
        text: 'Metal',
      });
    }
    if (udz_szklo !== '0.00') {
      seriesFrakOdpadStrukt.push({
        values: [parseFloat(udz_szklo)],
        text: 'Szkło',
      });
    }
    if (udz_tw_szt !== '0.00') {
      seriesFrakOdpadStrukt.push({
        values: [parseFloat(udz_tw_szt)],
        text: 'Tworzywa sztuczne',
      });
    }

    const configFrakcOdpadStrukt = {
      type: 'pie',
      title: {
        text:
          'Udział poszczególnych frakcji w odpadach zebranych i przekazanych do recyklingu',
        wrapText: true,
        height: '100px',
        marginBottom: '20px',
        fontSize: '18px',
        fontFamily: 'Open Sans',
      },
      plot: {
        size: '65%',
        animation: {
          effect: '1',
          delay: '2000',
          sequence: '3',
        },
      },
      legend: {
        y: '80%',
      },
      series: seriesFrakOdpadStrukt,
    };

    return (
      <div className='wrapperOdpady'>
        <p className='title'>Statystyki - Gospodarka odpadami</p>
        {parseFloat(zb_sel) && parseFloat(zb_sel) !== 0.0 && (
          <ZingChart data={configTypZbior} />
        )}
        {udz_papier !== '0.00' &&
          udz_metal !== '0.00' &&
          udz_szklo !== '0.00' &&
          udz_tw_szt !== '0.00' && <ZingChart data={configFrakcOdpadStrukt} />}
        {il_odp_mieszk !== '0.00' && (
          <div className='odpadyNaMieszkańca'>
            <p className='titleSection'>
              Ilość odpadów wyprodukowanych w ciągu roku w przeliczeniu na 1
              mieszkańca miasta
            </p>
            <div className='trashIcon'>
              <p className='ilOdpMieszk'>{il_odp_mieszk}</p>
              <p className='jednostka'>kg/rok</p>
            </div>
          </div>
        )}
      </div>
    );
  }
}
