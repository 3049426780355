///state 2020 is for all cities

const filterYear = (state = '2020', action) => {
    switch (action.type) {
    case 'YEAR_CHANGE':
      return action.filter;
    case 'RESET_FILTER':
      return state = '2020';
    default: 
      return state;
    }
    
  };

  export const resetFilterYear = () =>({
    type: 'RESET_FILTER'
  })
  
  export const onChangeYear = (filter, citySize) => ({
    type: 'YEAR_CHANGE',
    filter: filter,
    citySize: citySize
  });

  export default filterYear;
  