import React from 'react';
import { Image, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import './BofArticle.scss';
import translations from '../translations';
import StatisticWoda from './StatisticMode/StatisticModeWoda';
import StatisticOdpady from './StatisticMode/StatisticModeOdpady';
import StatisticTransport from './StatisticMode/StatisticModeTransport';
import StatisticZielen from './StatisticMode/StatisticModeZielen';

function openUrl(prefix) {
  let url = window.location.href;
  if (url.indexOf('?') !== -1) {
    url = url.split('?')[0];
  }
  url = url.replace('#', '?path=');
  window.open(prefix + url);
}

export default class BofArticle extends React.Component {
  render() {
    const {
      children,
      title,
      content,
      image,
      provider,
      il_odp_mieszk,
      zb_sel,
      zb_niesel,
      udz_papier,
      udz_metal,
      udz_szklo,
      udz_tw_szt,
      wodociagi,
      kanalizacja,
      ocz_przydom,
      brak,
      brak_danych,
      pieszo,
      rower,
      samochod,
      sam_niskoem,
      tr_zbior,
      udz_zieleni,
      udzial_niezieleni,
      pomn_przyr,
      pn,
      pk,
      rez,
      n2k,
      ochk,
      zpk,
      st_dok,
      brak_fop,
      uz_eko,
    } = this.props;

    return (
      <div className='article bof--article'>
        {title && title !== 'null' && <h3>{title}</h3>}
        <p className='bof--image'>
          {image && <Image src={image} responsive />}
        </p>
        <p className='articleProvider'>{provider}</p>
        <div
          className='content'
          dangerouslySetInnerHTML={{ __html: content }}
        />
        {children && children.length && (
          <div className='additionalColumns'>
            {children[0]} {children[1]}
            {children[2]}
            {children[3]}
            {children[4]}
          </div>
        )}
        <hr />
        <p>
          <Button
            bsClass='btn-link'
            onClick={() => openUrl('https://www.facebook.com/sharer.php?u=')}
          >
            <span className='socicon-facebook' />{' '}
            {
              translations[window.BofConfig.globalLang]['Adds'][
                'shareOnFacebook'
              ]
            }
          </Button>
        </p>
        <div className='statistics'>
          {wodociagi && wodociagi !== '0.00' && (
            <StatisticWoda
              wodociagi={wodociagi}
              kanalizacja={kanalizacja}
              ocz_przydom={ocz_przydom}
              brak={brak}
              brak_danych={brak_danych}
            />
          )}
          {pieszo && pieszo !== '0.00' && (
            <StatisticTransport
              pieszo={pieszo}
              rower={rower}
              samochod={samochod}
              sam_niskoem={sam_niskoem}
              tr_zbior={tr_zbior}
            />
          )}
          {il_odp_mieszk && il_odp_mieszk !== '0.00' && (
            <StatisticOdpady
              il_odp_mieszk={il_odp_mieszk}
              zb_sel={zb_sel}
              zb_niesel={zb_niesel}
              udz_papier={udz_papier}
              udz_metal={udz_metal}
              udz_szklo={udz_szklo}
              udz_tw_szt={udz_tw_szt}
            />
          )}
          {udz_zieleni && udz_zieleni !== '0.00' && (
            <StatisticZielen
              udz_zieleni={udz_zieleni}
              udzial_niezieleni={udzial_niezieleni}
              pomn_przyr={pomn_przyr}
              pn={pn}
              pk={pk}
              rez={rez}
              n2k={n2k}
              ochk={ochk}
              uz_eko={uz_eko}
              zpk={zpk}
              st_dok={st_dok}
              brak_fop={brak_fop}
            />
          )}
        </div>
      </div>
    );
  }
}

BofArticle.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  content: PropTypes.string,
  image: PropTypes.string,
  provider: PropTypes.string,
};
