import energia_uczestnictwo from '../static/symbols/energia_uczestnictwo.png';
import energia_wyroznienie from '../static/symbols/energia_wyroznienie.png';
import energia_zwyciestwo from '../static/symbols/energia_zwyciestwo.png';
import gospodarkaOdpadami_uczestnictwo from '../static/symbols/gospodarkaOdpadami_uczestnictwo.png';
import gospodarkaOdpadami_wyroznienie from '../static/symbols/gospodarkaOdpadami_wyroznienie.png';
import gospodarkaOdpadami_zwyciestwo from '../static/symbols/gospodarkaOdpadami_zwyciestwo.png';
import gospodarkaWodna_uczestnictwo from '../static/symbols/gospodarkaWodna_uczestnictwo.png';
import gospodarkaWodna_wyroznienie from '../static/symbols/gospodarkaWodna_wyroznienie.png';
import gospodarkaWodna_zwyciestwo from '../static/symbols/gospodarkaWodna_zwyciestwo.png';
import mobilnosc_uczestnictwo from '../static/symbols/mobilnosc_uczestnictwo.png';
import mobilnosc_wyroznienie from '../static/symbols/mobilnosc_wyroznienie.png';
import mobilnosc_zwyciestwo from '../static/symbols/mobilnosc_zwyciestwo.png';
import zielenMiejska_uczestnictwo from '../static/symbols/zielenMiejska_uczestnictwo.png';
import zielenMiejska_wyroznienie from '../static/symbols/zielenMiejska_wyroznienie.png';
import zielenMiejska_zwyciestwo from '../static/symbols/zielenMiejska_zwyciestwo.png';

export function getSymbol(index) {
  // eslint-disable-next-line
  switch (index) {
    case 'energia1':
      return energia_zwyciestwo;
    case 'odpady1':
      return gospodarkaOdpadami_zwyciestwo;
    case 'woda1':
      return gospodarkaWodna_zwyciestwo;
    case 'mobilnosc1':
      return mobilnosc_zwyciestwo;
    case 'zielen1':
      return zielenMiejska_zwyciestwo;
    case 'energia3':
      return energia_wyroznienie;
    case 'odpady3':
      return gospodarkaOdpadami_wyroznienie;
    case 'woda3':
      return gospodarkaWodna_wyroznienie;
    case 'mobilnosc3':
      return mobilnosc_wyroznienie;
    case 'zielen3':
      return zielenMiejska_wyroznienie;
    case 'energia2':
      return energia_uczestnictwo;
    case 'odpady2':
      return gospodarkaOdpadami_uczestnictwo;
    case 'woda2':
      return gospodarkaWodna_uczestnictwo;
    case 'mobilnosc2':
      return mobilnosc_uczestnictwo;
    case 'zielen2':
      return zielenMiejska_uczestnictwo;
  }
}

export function getSize(styl) {
  if (styl.indexOf('duży') > -1) {
    return 0.4;
  } else return 0.35;
}

export function getZIndex(styl) {
  if(styl === 'odpady1' || styl === 'energia1' || styl === 'zielen1' || styl==='mobilnosc1' || styl==='woda1'){
    return 100;
  } else if (styl === 'odpady3' || styl === 'energia3' || styl === 'zielen3' || styl==='mobilnosc3' || styl==='woda3'){
    return 80;
  } else {
    return 10;
  }
}

export function getEdycja(edycja) {
  if(edycja !==2017){
    return 0;
  }else{
    return 1;
  }
}
