import React from 'react';
import Nav from '../components/Nav';
import { connect } from 'react-redux';
import {
  MapPadding,
  VectorLayer,
  olUtils,
  provideMapState,
  WmsLayer
} from 'react-openlayers';
import { loadNav } from '../redux/nav';
import PropTypes from 'prop-types';
import { provideViewSize } from '../hocs/provideViewSize';
import PageView from '../views/PageView';
import './OmBofLayout.scss';
import './ArticleDetailLayout.scss';

const mapDispatchToProps = {
  loadNav,
  provideMapState
};

const mapStateToProps = state => ({
  nav: state.nav.nav
});

const bofFeature = olUtils.createFeatureFromGeometry(
  olUtils.createPoint([587865, 8497152])
);

export class OmBofLayout extends React.Component {
  componentDidMount() {
    const { loadNav, fitViewAndZoom } = this.props;
    loadNav();
    fitViewAndZoom(bofFeature.getGeometry(), 17);
    document.getElementsByClassName(
      'react-openlayers--show-location'
    )[0].style.display = 'inline';

    document.getElementsByClassName('ol-zoom ol-control')[0].style.display =
      'inline';
    document.getElementsByClassName('layer-switch--button-container')[0].style.display =
      'inline';
    document.querySelector('.tipsbox-btn-globe').style.display = 'inline';
  }

  componentDidUpdate() {
    try {
      window.FlickrEmbedr.detectImages('inline');
      window.FlickrEmbedr.processImages();
    } catch (ex) {
      // eslint-disable-next-line no-console
      console.warn(ex);
    }
  }

  calcMapPadding() {
    const { viewHeight, viewWidth } = this.props;

    if (viewWidth < 769) {
      return [80, 0, viewHeight / 2 - 48 / 2, 0];
    }
    return [80, viewWidth / 2, 0, 0];
  }

  get params() {
    let { params, location } = this.props;

    params.uri = location.pathname.substring(
      location.pathname.indexOf('/') + 1,
      location.pathname.length
    );
    return params;
  }

  render() {
    const { nav, location } = this.props;

    return (
      <div className="article-detail-layout--root ombof-layout--root">
        <MapPadding padding={this.calcMapPadding()} />
        {location.pathname.indexOf('ombof') !== -1 && (
          <VectorLayer
            layerName="ombof--layer"
            featureStyle={{
              pointRadius: 8,
              fillColor: '22 32 94',
              fillOpacity: 90,
              strokeOpacity: 20,
              strokeWidth: 5
            }}
            features={[bofFeature]}
          />
        )}
        {location.pathname.indexOf('marintavfall') !== -1 && (
          <div>
            <WmsLayer
              uri="https://bofogc.avinet.no/mapserv.ashx"
              id="plastviker_oygraden_flater"
              layerName="layer_48"
              version="1.1.1"
            />
            <WmsLayer
              uri="https://bofogc.avinet.no/mapserv.ashx"
              id="plastviker_oygraden_flater"
              layerName="layer_53"
              version="1.1.1"
            />
            <WmsLayer
              uri="https://bofogc.avinet.no/mapserv.ashx"
              id="plastviker_oygraden_flater"
              layerName="layer_54"
              version="1.1.1"
            />
          </div>
        )}
        <Nav nav={nav} />
        <div className="article-detail-layout--components">
          <PageView params={this.params} />
        </div>
      </div>
    );
  }
}

OmBofLayout.propTypes = {
  params: PropTypes.object.isRequired,
  location: PropTypes.object,
  nav: PropTypes.array,
  loadNav: PropTypes.func,
  viewWidth: PropTypes.number,
  viewHeight: PropTypes.number,
  fitViewAndZoom: PropTypes.func
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(provideViewSize(provideMapState(OmBofLayout)));
