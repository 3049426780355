import React from 'react';
import ZingChart from 'zingchart-react';
import './statisticModeTransport.css';

export default class StatisticTransport extends React.Component {
  render() {
    const { pieszo, rower, samochod, sam_niskoem, tr_zbior } = this.props;

    const seriesUdzialTrans = [];
    if (pieszo !== '0.00') {
      seriesUdzialTrans.push({ values: [parseFloat(pieszo)], text: 'Pieszo' });
    }
    if (rower !== '0.00') {
      seriesUdzialTrans.push({ values: [parseFloat(rower)], text: 'Rower' });
    }
    if (samochod !== '0.00') {
      seriesUdzialTrans.push({
        values: [parseFloat(samochod)],
        text: 'Samochód',
      });
    }
    if (sam_niskoem !== '0.00') {
      seriesUdzialTrans.push({
        values: [parseFloat(sam_niskoem)],
        text: 'Samochód nisko emisyjny',
      });
    }
    if (tr_zbior === '0.00' || tr_zbior === '0.0') {
      seriesUdzialTrans.push({
        values: [parseFloat(tr_zbior)],
        text: 'Transport zbiorowy',
      });
    }

    const configUdzialTrans = {
      type: 'pie',
      plot: {
        animation: {
          effect: '1',
          delay: '2000',
          sequence: '3',
        },
        size: '65%',
      },
      title: {
        text: 'Podział ruchu drogowego wg środków transportu',
        wrapText: true,
        height: '50px',
        fontFamily: 'Open Sans',
      },
      legend: {
        y: '80%',
      },
      series: seriesUdzialTrans,
    };

    return (
      <div className='transport'>
        <p className='title'>Statystyki - Mobilność zrównoważona</p>
        {pieszo && <ZingChart data={configUdzialTrans} />}
      </div>
    );
  }
}
