import React, { useMemo, useCallback } from 'react';
import {
  ClusterSource,
  clusterStackStyle,
  clusterText,
  Select,
} from 'react-openlayers';
import { Style, Icon, Circle, Fill } from 'ol/style';
import { getSymbol, getSize, getZIndex, getEdycja } from '../utils/Icons';
import { connect } from 'react-redux';
import filterArticles from '../redux/filterArticles';

const mapStateToProps = (state) => {
  
  return({
  citySize: state.citySize.citySize,
  filterYear: state.filterYear,
  articles: state.articles.articles,
  articlesLoading: state.articles.loading,
  articleLoadState: state.articles.loadState,
})};

const clusterLabelText = clusterText({
  color: '#333',
  font: '600 10px sans-serif',
});

const filterByYear = (articles, filter, citySize ) =>{
  let newArticles = [];
  articles.map(article => {
    if(filter !== '2020') {
      if(citySize==='wszystkie'){
        if(article[1].properties.edycja === parseInt(filter)) {
          newArticles.push(article);
        }
      }else if(article[1].properties.kat_wielkosci===citySize){
        if(article[1].properties.edycja === parseInt(filter)) {
          newArticles.push(article);
        }
      }
    } else {
      if(citySize==='wszystkie'){
        newArticles.push(article);
      } else if(article[1].properties.kat_wielkosci===citySize) {
        newArticles.push(article);
      }
    } 
  });
  return newArticles;
};

const styleFn = (f) => {
  //const edycja = articles[articles.key].properties.edycja;
  const edycja = getEdycja(f.get('edycja'));
  const size = getSize(f.get('style'));
  const symbol = getSymbol(f.get('sourceDataIndex'));
  const zIndex = getZIndex(f.get('sourceDataIndex'));
  

  if (!size || !symbol) return new Style();
  return new Style({
    image: new Icon({
      src: symbol,
      scale: size,
    }),
    zIndex: zIndex,
  
  });
};

const itemClusterStyle = clusterStackStyle({
  clusterLabelText,
  clusterLabelOffsetX: 12,
  clusterLabelOffsetY: 12,
  clusterLabelBackgroundImage: new Circle({
    radius: 8,
    fill: new Fill({ color: '#fff' }),
  }),
  featureStyle: styleFn,
});

const ArticleLayer = ({
  articles,
  articlesLoading,
  onFeatureClick,
  activeSources,
  filterYear,
  citySize
}) => {

  const features = useMemo(() => {
    
    if (!articlesLoading) {
      return Object.entries(articles)
        .filter((a) => activeSources.includes(a[1].id.split('_')[0]))
        
        .map((a) => {
          a[1].olFeature.setProperties({
            style: a[1].properties.styl,
            sourceDataIndex: a[1].id.split('_')[0],
            id: a[1].id,
            edycja: a[1].properties.edycja
          });
          return a[1].olFeature;
          
        });
    }

  }, [articlesLoading, filterByYear(Object.entries(articles), filterYear, citySize), activeSources]);

  const onItemSelect = useCallback(
    (f) => {
      if (!f.length) {
        return;
      }
      const cluster = f[0];
      const features = cluster.get('features');
      if (features.length === 1) {
        const f = features[0];
        
        onFeatureClick(f.get('id'));
      }
      return false;
    },
    [onFeatureClick]
  );
  return (
    <ClusterSource
      key={'articles-layer'}
      layerName='articlelayer'
      features={features || []}
      style={itemClusterStyle}
      clusterThreshold={15}
    >
      <Select onSelect={onItemSelect} />
    </ClusterSource>
  );
};

export default connect(mapStateToProps)(ArticleLayer);
