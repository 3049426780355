import { getState } from 'react';
import store from './store';


const filterArticles = ( state={}, action) => {

    switch (action.type) {
    case 'YEAR_CHANGE':
        const newArticles = {};
        const filter = action.filter;
        const articles = store.getState().articles.articles;

        for(const key in articles){
            const article = articles[key];
            const edition = articles[key].properties.edycja;
            if(filter!== '2020'){

                if(parseInt(filter) === edition){
                    newArticles[key] = article;
                }

            }else {
                newArticles[key] = article;
            }
        }

      return newArticles;
      
    default: 
      return state;
    }
  };

  export default filterArticles;
