import React from 'react';
import { browserHistory } from 'react-router';
import { Button, Glyphicon } from 'react-bootstrap';
import {
  Articles,
  ensureArticleModuleConfig,
  articleActions,
} from 'react-article-module';
import PropTypes from 'prop-types';
import { WmsLayer } from 'react-openlayers';
import LazyLoad from '../lib/lazyload';
import './ArticleView.scss';
import WmsLegends from '../components/WmsLegends';
import LegendBtns from '../components/LegendBtns';
import { translations as ArticlModuleTranslations } from 'react-article-module';
import { connect } from 'react-redux';
import Icon from '../components/Icon';
import translations from '../translations';
import ArticleLayer from '../containers/ArticleLayer';
import { bindActionCreators } from 'redux';
import {actions} from 'react-article-module/es/redux/articles';

const baseUrl = 'obiekt';
const sourceUrl = '/mapa';

const mapStateToProps = (state) => {

  return({
  citySize: state.citySize,
  filterYear: state.filterYear,
  articles: state.articles.articles,
  articlesLoading: state.articles.loading,
  articleLoadState: state.articles.loadState,
})};

class ArticleView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showLegends: false,
    };
  }

  componentDidMount() {
    this.lazyLoad = new LazyLoad({
      threshold: 500,
      container:
        window.innerWidth < 769
          ? window
          : document.getElementById('articleView-scrollPane'),
      elements_selector: '.article-entry img',
    });
  }

  componentWillUnmount() {
    this.lazyLoad.destroy();
  }

  getTranslations(lang) {
    return Object.assign(translations[lang], ArticlModuleTranslations[lang]);
  }

  onFeatureClick = (id) => {
    browserHistory.push(baseUrl + '/' + id.replace('_', '/'));
  };

  onSourceSelect = (sources) => {
    const { actions } = this.props;

    browserHistory.push(sourceUrl + '/' + sources.join('+'));
    actions.setActiveSources(sources.join('+'));
  };

  get activeSources() {
    let {
      params: { activeSources },
      sources,
    } = this.props;


    activeSources = activeSources ? activeSources.split('+') : [];

    if (!activeSources.length) {
      activeSources = sources
        .filter((s) => {
          return s.pressed && s.dataIndex;
        })
        .map((item) => item.dataIndex);
    }
    return activeSources;
  }

  handleFilterButton = () =>{
    const filters = document.querySelector('.filters');

    filters.classList.toggle('open');
  };

  handleOpenFilter = (e) =>{
    const element = e.currentTarget;
    element.classList.toggle('active');
    element.nextElementSibling.classList.toggle('active');
    console.log(e.currentTarget.nextElementSibling);
  }

  render() {
    const { enableArticleComments } = this.props;
    const { showLegends } = this.state;
    const pushPath = (path) => browserHistory.push('/' + path);
    const activeSources = this.activeSources;
    const openArticleId =
      this.props.params && this.props.params.pageId
        ? this.props.params.activeSources + '_' + this.props.params.splat
        : undefined;
    return (
      <>
        <div className='article-view--container' id='articleView-scrollPane'>
          {enableArticleComments && (
            <div className={''}>
              <Button bsStyle='default' disabled>
                <Glyphicon glyph='th-list' />
              </Button>
              <Button bsStyle='primary' onClick={() => pushPath('comments')}>
                <Glyphicon glyph='comment' />
              </Button>
            </div>
          )}
          <div className='article-view--buttonbar'>
            <LegendBtns
              showLegends={showLegends}
              onShowLegendsClick={(showLegends) => this.setState({ showLegends })}
            />
          </div>
          {showLegends && (
            <WmsLegends
              urls={[
                'http://gridw.geopanel.eu/WebServices/generic/Media.asmx/Download?uuid=023bb674-9e9c-48c8-b3d6-b6a3c7c6b9cb',
                //,
                //'http://wms.gridw.geopanel.eu/qgis.ashx?map=6b26bb62-b800-4ed2-9fed-8d3edd7089c8&LAYERS=Szlaki piesze&FORMAT=image/png&VERSION=1.1.1&SERVICE=WMS&REQUEST=GetLegendGraphic&LAYERTITLE=true&ITEMFONTSIZE=9&LAYERFONTSIZE=11'
              ]}
            />
          )}
          <div className={showLegends ? 'article-view--hidden' : ''}>
            <Articles
              translations={this.getTranslations(window.BofConfig.globalLang)}
              activeSources={activeSources}
              onContentChange={() => this.lazyLoad.update()}
              onSourceSelect={this.onSourceSelect}
              onArticleOpen={(article) => {
                pushPath(baseUrl + '/' + article.id.replace('_', '/'));
                window.scrollTo(0, 0);
              }}
              thumbnailClassName='bof--thumbnail'
              useHoverStyle
              sourceSelectorPlaceholder={(count) =>
                count === 1
                  ? 
                    translations[window.BofConfig.globalLang]['Adds'][
                      'sourceSelectorFixed'
                    ]
                  : translations[window.BofConfig.globalLang]['Adds'][
                      'sourceSelectorFixed'
                    ]
              }
              openArticleId={openArticleId}
              hideLayers
            />
            <ArticleLayer
              onFeatureClick={this.onFeatureClick}
              activeSources={activeSources}
            />
          </div>
        </div>
        
        
       
      </>
    );
  }
}

ArticleView.propTypes = {
  params: PropTypes.object,
  enableArticleComments: PropTypes.bool,
  activeSources: PropTypes.array,
  t: PropTypes.func,
};

const mapDispatchToProps = (dispatch) =>{

  return ({
  onChangeYear: (e) => dispatch({
    type: 'YEAR_CHANGE',
    filter: e.currentTarget.value
  }),
  handleCitySize: (e) => dispatch({
    type: 'CITYSIZE_CHANGE',
    citySize: e.currentTarget.value
  }),
  actions
  //return bindActionCreators(Object.assign({}, articleActions), dispatch);
})};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ensureArticleModuleConfig(ArticleView));
