import React from 'react';
import ZingChart from 'zingchart-react';
import './statisticModeWoda.css';

export default class StatisticWoda extends React.Component {
  render() {
    const {
      wodociagi,
      kanalizacja,
      ocz_przydom,
      brak,
      brak_danych,
    } = this.props;

    let seriesKanalizWodo = [];
    let seriesSystemOczyszcz = [];

    if (wodociagi !== '0.00') {
      seriesKanalizWodo = [
        {
          values: [parseFloat(wodociagi), parseFloat(kanalizacja)],
        },
      ];
    }

    if (ocz_przydom !== '0.00') {
      seriesSystemOczyszcz.push({
        values: [parseFloat(ocz_przydom)],
        text: 'oczyszczalnia przydomowa',
      });
    }
    if (kanalizacja !== '0.00') {
      seriesSystemOczyszcz.push({
        values: [parseFloat(kanalizacja)],
        text: 'system kanalizacyjny',
      });
    }
    if (brak !== '0.00') {
      seriesSystemOczyszcz.push({
        values: [parseFloat(brak)],
        text: 'brak systemu oczyszczania ścieków',
      });
    }
    if (brak_danych !== '0.00') {
      seriesSystemOczyszcz.push({
        values: [parseFloat(brak_danych)],
        text: 'brak danych',
      });
    }

    const configKanalizWodo = {
      type: 'bar',
      title: {
        text:
          'Odestek mieszkańców podłączonych do systemu wodociągowego i kanalizacyjnego',
        fontSize: 18,
        offsetX: 10,
        offsetY: 10,
        height: 30,
        wrapText: true,
        fontFamily: 'Open Sans',
      },
      scaleX: {
        labels: ['', ''],
      },
      labels: [
        {
          text: '% mieszkańców podłączonych do sieci wodociągowej ',
          width: '180px',
          height: '50px',
          wrapText: true,
          x: '10%',
          y: '85%',
        },

        {
          text: '% mieszkańców podłączonych do systemu kanalizacyjnego',
          width: '180px',
          height: '50px',
          textAlign: 'center',
          x: '50%',
          y: '85%',
          wrapText: true,
          fontFamily: 'Open Sans',
        },
      ],
      plot: {
        barWidth: '40%',
        animation: {
          effect: '1',
          delay: '2000',
          sequence: '3',
        },
      },
      series: seriesKanalizWodo,
    };

    const configStrukturaOczyszcz = {
      type: 'pie',
      title: {
        text: 'Struktura systemu oczyszczania ścieków w mieście',
        wrapText: true,
        height: '50px',
        fontFamily: 'Open Sans',
      },
      plot: {
        size: '63%',
        animation: {
          effect: '1',
          delay: '2000',
          sequence: '3',
        },
      },
      legend: {
        y: '80%',
      },
      series: seriesSystemOczyszcz,
    };

    return (
      <div className='woda'>
        <p className='title'>Statystyki - gospodarka wodna</p>
        {wodociagi && (
          <ZingChart data={configKanalizWodo} width='100%' height='80%' />
        )}
        <ZingChart data={configStrukturaOczyszcz} />
      </div>
    );
  }
}
