import React from 'react';
import { connect } from 'react-redux';
import Nav from '../components/Nav';
import PropTypes from 'prop-types';
import { MapPadding, Zoom } from 'react-openlayers';
import './MapLayout.scss';
import { loadNav } from '../redux/nav';
import { provideViewSize } from '../hocs/provideViewSize';
import Icon from '../components/Icon';
import { Button } from 'react-bootstrap';
import PopupBox from '../components/PopupBox';
import { ensureArticleModuleConfig } from 'react-article-module';
import translations from '../translations';


const mapDispatchToProps = (dispatch) =>{

  return ({
  onChangeYear: (e) => dispatch({
    type: 'YEAR_CHANGE',
    filter: e.currentTarget.value
  }),
  handleCitySize: (e) => dispatch({
    type: 'CITYSIZE_CHANGE',
    citySize: e.currentTarget.value
  }),
  loadNav
  //return bindActionCreators(Object.assign({}, articleActions), dispatch);
})};

const mapStateToProps = (state) => ({
  nav: state.nav.nav,
  citySize: state.citySize,
  filterYear: state.filterYear,
  articles: state.articles.articles,
  articlesLoading: state.articles.loading,
  articleLoadState: state.articles.loadState,

});

export class MapLayout extends React.Component {
  constructor() {
    super();
    this.state = {
      showTipBox: true,
      tmpHideTipBox: false,
    };

    this.onTipBoxVisibilityChange = this.onTipBoxVisibilityChange.bind(this);
  }

  componentDidMount() {
    document.getElementsByClassName(
      'react-openlayers--show-location'
    )[0].style.display = 'inline';

    document.getElementsByClassName(
      'layer-switch--button-container'
    )[0].style.display = 'inline';
    document.getElementsByClassName(
      'layer-switch--container'
    )[0].style.display = 'inline';
    document.querySelector('.tipsbox-btn-globe').style.display = 'inline';

    const tipBoxDeactivated = localStorage.getItem('bof_tipboxdeactivated');
    const tipboxTimestamp = localStorage.getItem('bof_tipboxtimestamp');

    if (tipBoxDeactivated) {
      this.setState({
        showTipBox: false,
      });
    }

    if (tipboxTimestamp) {
      const diff = Math.abs(Date.now() - tipBoxDeactivated);
      const diffDays = Math.floor(diff / (1000 * 3600 * 24));
      if (diffDays > 0) {
        this.setState({
          tmpHideTipBox: true,
        });
      }
    }

    this.props.loadNav();
  }

  onTipBoxVisibilityChange(neverShowAgain) {
    if (neverShowAgain) {
      localStorage.setItem('bof_tipboxdeactivated', true);
    } else {
      localStorage.removeItem('bof_tipboxdeactivated');
      localStorage.setItem('bof_tipboxtimestamp', Date.now());
    }
    this.setState({ showTipBox: !this.state.showTipBox });
  }

  calcMapPadding() {
    const { viewHeight, viewWidth, location } = this.props;
    if (viewWidth < 769) {
      return [80, 0, viewHeight / 4, 0];
    }

    if (location.pathname.indexOf('/obiekt/') === 0) {
      return [80, viewWidth / 3.5, 0, 0];
    }
    return [80, 420, 0, 0];
  }

  handleFilterButton = () =>{
    const filters = document.querySelector('.filters');

    filters.classList.toggle('open');
  };

  handleOpenFilter = (e) =>{
    const element = e.currentTarget;
    element.classList.toggle('active');
    element.nextElementSibling.classList.toggle('active');
    console.log(e.currentTarget.nextElementSibling);
  }

  render() {
    const { children, nav, location,  articles, filterYear, handleCitySize, onChangeYear, citySize } = this.props;
    const { showTipBox, tmpHideTipBox } = this.state;
    const checkBoxChecked = localStorage.getItem('bof_tipboxdeactivated');

    return (
      <div className='map-layout--root'>
        <MapPadding padding={this.calcMapPadding()} />
        <Nav nav={nav} />
        <div className='map-layout--components'>
          <Zoom
            zoomInTipText={translations[window.BofConfig.globalLang]['Adds']['zoomInTipLabel']}
            zoomOutTipText={translations[window.BofConfig.globalLang]['Adds']['zoomOutTipLabel']}
          />
          <Button
            className='tipsbox-btn-help'
            onClick={() =>
              this.setState({ showTipBox: true, tmpHideTipBox: false })
            }
            title="Pomoc"
          >
            <Icon name='info' height={22} width={22} />
          </Button>
          <div className='map-filter--components'>
          <Button className="tipsbox-btn-filter" title="Filtruj" onClick = {this.handleFilterButton}>
            <Icon name="filter" height={22} width={22} />
          </Button>
          
          <div className="filters open">
          
          <div className="TimeSliderWrapper">
            <p className='titleFilter accordion' onClick = {this.handleOpenFilter}>Wybierz edycję konkursu:</p>
            <div className="filterPanel">
              <input type="range" className="timeSlider" min="2013" max="2020" list="list" step="1" value={filterYear} onChange={ onChangeYear } />
              <datalist id="list" className="range__list">
                <option className="range__opt smallChange2013" value="2013" filter='2013'>2013</option>
                <option className="range__opt smallChange2014" value="2014" filter='2014'>2014</option>
                <option className="range__opt smallChange2015" value="2015" filter='2015'>2015</option>
                <option className="range__opt smallChange2016" value="2016" filter='2016'>2016</option>
                <option className="range__opt smallChange" value="2017" filter='2017' >2017</option>
                <option className="range__opt smallChange2018" value="2018" filter='2018'>2018</option>
                <option className="range__opt smallChange2019" value="2019" filter='2019'>2019</option>
                <option className="range__opt wszystkie" value="all" filter='2020'>&sum;</option>
              </datalist>
            
              <p className="filterName">wybrana edycja: 
                <span> {filterYear==='2020'? 'wszystkie ': filterYear}</span>
              </p>
              </div>
          </div>
          <div className="bigSmallCityWrapper">
            <p className='titleFilter accordion' onClick = {this.handleOpenFilter} >Wybierz wielkość miast:</p>
            <div className="filterPanel citySize">

              <div className="allCitis">
                  <input type='radio' value='wszystkie' id='wszystkie' name='city' checked={ citySize.allCity } onChange={handleCitySize} />
                  <label htmlFor='wszystkie'>wszystkie</label>
              </div>
              <div className="bigCity">
                  <input type='radio' value ='powyżej 100 tys. mieszkańców' id='duze' name='city'checked={ citySize.cityBig } onChange={handleCitySize} />
                  <label htmlFor='duze'>powyżej 100 tys. mieszkańców</label>
              </div>
              <div className="smallCity">
                  <input type="radio" value ='poniżej 100 tys. mieszkańców' id="male" name='city' checked={ citySize.citySmall } onChange={handleCitySize} />
                  <label htmlFor='male'>poniżej 100 tys. mieszkańców</label>
              </div>
              
              
              </div>  
          </div>
          <div className='closeFilter' onClick = {()=> {
            const filters = document.querySelector('.filters');
            filters.classList.toggle('open');
            }}>X
          </div>
          {/* <h3>Filtry</h3> */}
        </div>
        </div>
          {showTipBox && !tmpHideTipBox && (
            <PopupBox
              onClose={this.onTipBoxVisibilityChange}
              closeBtnText={
                translations[window.BofConfig.globalLang]['Adds'][
                  'closeBtnText'
                ]
              }
              checkBoxText={
                translations[window.BofConfig.globalLang]['Adds'][
                  'checkBoxText'
                ]
              }
              checkBoxChecked={!!checkBoxChecked}
            />
          )}
          <div
            className={
              location.pathname.indexOf('/obiekt/') === 0
                ? 'article-view--hidden'
                : ''
            }
          >
            {/* <ArticleView params={params} /> */}
          </div>
          {children}
        </div>
      </div>
    );
  }
}

MapLayout.propTypes = {
  children: PropTypes.node,
  nav: PropTypes.array,
  loadNav: PropTypes.func,
  viewWidth: PropTypes.number,
  viewHeight: PropTypes.number,
  params: PropTypes.object,
  sources: PropTypes.array.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(provideViewSize(ensureArticleModuleConfig(MapLayout)));
