const citySize = (state = {citySize: 'wszystkie', cityBig: false, citySmall: false, allCity: true }, action) => {
    switch (action.type) {
        case 'CITYSIZE_CHANGE':
            return ({
              citySize: action.citySize,
              cityBig: (action.citySize === "powyżej 100 tys. mieszkańców") ? true : false,
              citySmall: (action.citySize === "poniżej 100 tys. mieszkańców") ? true : false,
              allCity: ( action.citySize === "wszystkie") ? true : false
            });
        case 'RESET_CITYSIZE':
          return state = {citySize: 'wszystkie', cityBig: false, citySmall: false, allCity: true };
       default: 
      return state;
    }
}

export const resetCitySize = () =>({
  type: 'RESET_CITYSIZE'
})

export const onChangeCitySize = citySize => ({
  type: 'CITYSIZE_CHANGE',
  citySize: citySize,
});

export default citySize;
