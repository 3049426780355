import React from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import {
  ArticleWindow,
  ArticleComments,
  ensureArticleModuleConfig,
} from 'react-article-module';
import PropTypes from 'prop-types';
import BofArticle from '../components/BofArticle';
import Title from '../components/Title';
import WmsLegends from '../components/WmsLegends';
import LegendBtns from '../components/LegendBtns';
import './ArticleDetailView.scss';
import translations from '../translations';
import ArticleLayer from '../containers/ArticleLayer';

const mapStateToProps = (state) => ({
  activeSources: state.articles.activeSources,
});

export class ArticleDetailView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showLegends: false,
    };
  }

  render() {
    const {
      params: { pageId, splat },
      activeSources,
      enableArticleComments,
      resetYear,
      resetCitySize
    } = this.props;

    const { showLegends } = this.state;
    const pushPath = (path) => browserHistory.push('/' + path);

    return (
      <div className='article-detail-layout--root'>
        <div className='article-detail-layout--components'>
          <div className='article-detail-view--container'>
            <Title
              title={
                translations[window.BofConfig.globalLang]['Adds']['pageTitle']
              }
            />
            <div className='article-detail-view--buttonbar'>
              <LegendBtns
                showLegends={showLegends}
                onShowLegendsClick={(showLegends) =>
                  this.setState({ showLegends })
                }
              />
            </div>
            {showLegends && (
              <WmsLegends
                urls={[
                  'http://gridw.geopanel.eu/WebServices/generic/Media.asmx/Download?uuid=023bb674-9e9c-48c8-b3d6-b6a3c7c6b9cb',
                ]}
              />
            )}
            <div className={showLegends ? 'article-detail-view--hidden' : ''}>
              <ArticleWindow
                articleComponent={BofArticle}
                id={pageId + '_' + splat}
                onClose={() => {
                  let path = 'mapa';
                  if (activeSources) {
                    path += '/' + activeSources;
                  }
                  pushPath(path);

                }}
                maxZoom={12}
                hideOpenExternalBtn
                hideLayers
              />
            </div>
            {enableArticleComments /* TODO: Cannot use pageId for comments, must use article globalId */ && (
              <ArticleComments id={pageId} hasForm />
            )}
          </div>
        </div>
        <ArticleLayer
          onFeatureClick={this.onFeatureClick}
          activeSources={[pageId]}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) =>{
  return ({
    resetYear: () => dispatch({
      type: 'RESET_FILTER'
  }),
  resetCitySize: ()=> dispatch({
    type: 'RESET_CITYSIZE'
  })
})

};

ArticleDetailView.propTypes = {
  params: PropTypes.object.isRequired,
  activeSources: PropTypes.string,
  enableArticleComments: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  ensureArticleModuleConfig(ArticleDetailView));
